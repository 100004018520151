/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
          event.preventDefault();
          $(this).ekkoLightbox();
        });


        $('lottie-player svg').css('transform', 'none');

        $('section.product-feature .product-feature-links a.product-feature-link').on('click', function (e) {
          e.preventDefault();
          var mediaID = $(this).attr('href').replace(/^.*?(#|$)/,'');
          var featureBlock = $(this).parents('section.product-feature');
          $(featureBlock).find('a.product-feature-link').removeClass('active');
          $(this).addClass('active');

          $(featureBlock).find('.media figure').addClass('d-none');
          $(featureBlock).find('.media figure#media-'+mediaID).removeClass('d-none');

          $(featureBlock).find('.detail-tiles').addClass('d-none');
          $(featureBlock).find('.detail-tiles#tiles-'+mediaID).removeClass('d-none');

          return false;
        });


        $('.related-tiles .related-tile figure').matchHeight();
        $('.footer-testimonials .testimonial').matchHeight();

        $('.testimonials-carousel').slick({
          dots: true,
          autoplay: true,
          autoplaySpeed: 8500
        });

        /****************
        **  add Active state to form labels
        *****************/
        $('.gfield input,.gfield select,.gfield textarea').on('focus blur', function(e){
          var label = $('label[for="' + this.id + '"]');
          label[e.type === 'focus' ? 'addClass' : 'removeClass']('active');
        });

        $('#gform_submit_button_4').attr('disabled', 'disabled');

        $('form#gform_4 input#input_4_1').on( "keyup", function() {
          var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if(regex.test($('form#gform_4 input#input_4_1').val())) {
            $('#gform_submit_button_4').removeAttr('disabled');
          } else {
            $('#gform_submit_button_4').attr('disabled', 'disabled');
          }
        });

        /****************
        **  SVG swap out with PNG
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }


        /****************
        **  Change DEL to MARK in main.main
        *****************/
        var $del = $("main.main del");
        $del.replaceWith(function () {
          return $('<mark/>', {
            class: 'myClass',
            html: this.innerHTML
          });
        });

        /****************
        **  Close Mobile Menu at desktop sizes
        *****************/
        $('#mobileMenu').on('shown.bs.modal', function () {
          $( window ).resize(function() {
            var width = $(window).width();
            if (width > 991) {
              $('#mobileMenu').modal('hide');
            }
          });
        });

        /****************
        **  Primary Nav - dropdown modifications
        *****************/
        // remove link functaionality from Dropdown Headers
        $('#menu-primary-navigation .dropdown-menu .dropdown-header').on('click', function (e) {
          e.preventDefault();
          return false;
        });

        // make .dropdown-2col into 2 columns
        $('.dropdown-2col .dropdown-menu').wrapInner("<div class='row'><div class='col-6'></div></div>");
        var boundary = $('.dropdown-2col .dropdown-menu .dropdown-header').eq(1);
        $("<div class='col-6'>").insertAfter(boundary.parent()).append(boundary.nextAll().andSelf());

        // add style triangle to dropdown
        $('#menu-primary-navigation .dropdown-menu').append("<div class='dropdown-mark'></div>");


        /****************
        **  Sortable Tables
        *****************/
        $('#table-patients').DataTable({
          searching: false,
          paging: false,
          info: false,
          autoWidth: false,
//           scrollX: true,
          order: [[2, 'asc']],
          columns: [
            { orderable: false },
            null,
            null,
            null,
            null,
            null,
            null,
            { orderable: false }
          ],
          responsive: true
        });

        /****************
        **  Expand/close all accordion
        *****************/
        $('.faq-actions .expand-faqs').on('click', function (e) {
          e.preventDefault();
          $('#FAQs .card-header a').removeClass('collapsed');
          $('#FAQs .collapse').addClass('show');
          $('.faq-actions .expand-faqs').hide();
          $('.faq-actions .close-faqs').show();
        });
        $('.faq-actions .close-faqs').on('click', function (e) {
          e.preventDefault();
          $('#FAQs .card-header a').addClass('collapsed');
          $('#FAQs .collapse').removeClass('show');
          $('.faq-actions .close-faqs').hide();
          $('.faq-actions .expand-faqs').show();
        });

        $('.job-actions .expand-jobs').on('click', function (e) {
          e.preventDefault();
          $('#Jobs .card-header a').removeClass('collapsed');
          $('#Jobs .collapse').addClass('show');
          $('.job-actions .expand-jobs').hide();
          $('.job-actions .close-jobs').show();
        });
        $('.job-actions .close-jobs').on('click', function (e) {
          e.preventDefault();
          $('#Jobs .card-header a').addClass('collapsed');
          $('#Jobs .collapse').removeClass('show');
          $('.job-actions .close-jobs').hide();
          $('.job-actions .expand-jobs').show();
        });

        /****************
        **  Close Alert Bar
        *****************/
/*
        if( $('.alertbar-wrap').length ) {
          if (typeof Cookies.get('alertHider') === 'undefined') {
            $( ".alertbar-wrap" ).show();
            $('body').addClass('alertbar-engaged');
          }
        }
        $(".alertbar-wrap .closealert").on('click', function (e) {
          e.preventDefault();
          $( ".alertbar-wrap" ).remove();
          $('body').removeClass('alertbar-engaged');
          Cookies.set('alertHider', true);
          return false;
        });
*/

        /****************
        **  Integration/Partners galleries
        *****************/
        $('.ip-gallery').slickLightbox({
          itemSelector: '> .ip-gallery-item a',
          caption: 'caption',
          useHistoryApi: 'true',
          imageMaxHeight: '0.7'

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#input_4_1').attr("required", "required");




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // DOWNLOAD page
    'download': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-80').addClass('active');

      }
    },
    // BLOG page
    'blog': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-80').addClass('active');

      }
    },
     // NEWS page
    'news': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-80').addClass('active');

      }
    },
    // CONTACT page
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page

        // form tweaks
//         $('.gform_wrapper form #field_1_1')

      }
    },
    // SINGLE POST
    'single_post': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-80').addClass('active');

      }
    },

    // SOLUTIONS pages
    'page_template_template_solutions': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-77').addClass('active');

      }
    },
    // FEATURES pages
    'page_template_template_features': {
      init: function() {
        // JavaScript to be fired on the about us page

        // Active state on Nav
        $('#menu-primary-navigation .nav-item-78').addClass('active');

        /****************
        **  Isolate # from Features Anchor link
        *****************/
        $('.feature-highlights .bricks .feature-brick').each(function(  ) {
          var fullUrl = $(this).attr("href");
          var urlParts = fullUrl.split("#");
          var newUrl = "#" + urlParts[urlParts.length - 1];
          $(this).attr("href",newUrl);
        });

        $('.feature-highlights .bricks .feature-brick').matchHeight();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
